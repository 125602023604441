import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskText'
})
export class MaskTextPipe implements PipeTransform {
  transform(text: string,isGuestUser: boolean): string { 
    if (isGuestUser) {
      // Mask the sensitive information, replace all characters with '*'.
      return text.replace(/./g, '*');
    } else {
      // If the user is not a guest, return the original value.
      return text;
    }
  }
}
