import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class StateService {
private activeSectionSubject = new BehaviorSubject<string>('myMeds');
activeSection$ = this.activeSectionSubject.asObservable();

    setActiveSection(section: string) {
        this.activeSectionSubject.next(section);
    }
}
