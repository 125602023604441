import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  _loader: any;
    message: any = "";
  @HostListener("window:keydown", ["$event"])
  keyEventAlt(event: KeyboardEvent) {
      if (this._loader) {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
      }
  }
  constructor(private loaderService: LoaderService, private _cdr: ChangeDetectorRef) {}
ngOnInit() {
  this.loaderService
  .getMessage()
  .subscribe(loader =>{
          this._loader = loader;
          this._cdr.detectChanges();
      });
}

}
