export const environment = {
    production: false,
    //AUTH0_CALL_BACK: 'http://localhost:4200/callback',
    //API_BASE_URL : "https://localhost:7098/api/",
    AUTH0_CALL_BACK: 'https://patientengagementsvc.dev.eprimerx.com/callback',
   // API_BASE_URL : "https://localhost:7098/api/",
    API_BASE_URL : "https://patientengagementsvc.dev.eprimerx.com/api/",
    API_PRIMERX_PAY_BASE_URL : "https://staging.micromerchantsystems.com/PrimeRxPay/",
    domain: 'devprimerx.us.auth0.com', 
    clientId: 'Fb8m4JPrrH5LgZgg8I3LP6dwSK1CPeI5', 
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://api.dev.eprimerx.com"
    },
    isSecurePatientComm:true,
    Negotiate_apiBaseUrl: 'https://api.dev.eprimerx.com/primecomm',
    primecommproxy_BaseUrl:'https://primecommproxy.dev.eprimerx.com',
    primecomm_BaseUrl:'https://primecomm.dev.eprimerx.com/'
};
