import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrl: './error-popup.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class ErrorPopupComponent {
  @Input() show: boolean = false;
  @Input() errorMessage: string = '';
  @Output() close = new EventEmitter<void>();
  closePopup() {
    this.close.emit();
  }
}
