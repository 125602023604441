
export enum IdentifierTypeEnum {
    NPI = 1,
    NABP = 2,
    PATIENT = 3,
    PRESCRIBER = 4
}

export enum ParticipantTypeEnum {
    None = 0,
    PHARMACY = 1,
    PATIENT = 2,
}

export enum FiltersEnum {
    ALLHISTORY = 'All History',
    TODAY = 'Today',
    LASTONEWEEK = 'Last 1 Week',
    LASTONEMONTH = 'Last 1 Month',
    LASTSIXMONTHS = 'Last 6 Months',
    LASTONEYEAR = 'Last 1 Year'
}

export interface Message {
	date: string;
    message: string;
    isRead: Boolean;
      sender: {
		  type: number | string;
          identifier: number | string; 
          identifierType: number | string;
		  userName: string;
      },
      recipient: {
	      type: number | string; 
		  identifier: number | string; 
          identifierType: number | string;
		  userName: string;
      }
  }