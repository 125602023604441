import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimeUtcPipe'
})
export class DateTimeUtcPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let dateVal1 = null;
    let dateVal2 = null;
    if (value) {
        dateVal1 = moment.utc(value).local().format("MM/DD/YYYY");
        dateVal2 = moment.utc(value).local().format("MM/DD/YYYY hh:mm:ss A");
        if (moment(dateVal1, "MM/DD/YYYY", true).isValid()) {
            return (args?.length && args[0] === 'date') ? dateVal1: dateVal2;
        } else {
            return null;
        }
    }
    return null;
  }

}
