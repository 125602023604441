import { ElementRef, Injectable, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SMSservice } from '../../../../core/services/sms.service';
const MOBILE_VIEWPORT_MAX_WIDTH = 767;
const TABLET_VIEWPORT_MAX_WIDTH = 1024;
@Injectable({
    providedIn:'root'
})
export class CommmonUtil { 
    constructor(private ngZone: NgZone,private smsService:SMSservice,){}
    ConfirmedValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
            }
            if (control?.value !== matchingControl?.value) {
            matchingControl.setErrors({ confirmedValidator: true });
            } else {
            matchingControl.setErrors(null);
            }
        };
    }

    formatPhone(value:any){ 
        const areaCode = value.substring(0, 3);
        const firstPart = value.substring(3, 6);
        const secondPart = value.substring(6, 10);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
    }

    calculateAge(dateOfBirth: Date): number {
        const today = new Date();
        const dob = new Date(dateOfBirth);
    
        let age = today.getFullYear() - dob.getFullYear();
        const dobMonth = dob.getMonth();
        const todayMonth = today.getMonth();
        
        if (todayMonth < dobMonth || (todayMonth === dobMonth && today.getDate() < dob.getDate())) {
            age--;
        } 
        return age;
    }
    isBirthday(dateString: string): boolean { 
        const [monthStr, dayStr, yearStr] = dateString.split("/").map(part => parseInt(part, 10)); 
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; 
        const currentDay = currentDate.getDate(); 
        return currentMonth === monthStr && currentDay === dayStr;
    }

    scrollActiveButtonIntoView(breadcrumbs: ElementRef, activeSection: string): void {
        if (!breadcrumbs || !breadcrumbs.nativeElement) {
            return;
        }

        this.ngZone.run(() => {
            const buttons = breadcrumbs.nativeElement.querySelectorAll('button');
            const activeButton = Array.from(buttons).find((button: any) =>
            button.classList.contains('active')
            ) as HTMLElement;

            if (activeButton) {
            const scrollContainer = breadcrumbs.nativeElement;
            const activeButtonLeft = activeButton.offsetLeft;
            const scrollContainerWidth = scrollContainer.clientWidth;
            const activeButtonWidth = activeButton.clientWidth;
            const scrollLeft = activeButtonLeft - (scrollContainerWidth / 2) + (activeButtonWidth / 2);

            scrollContainer.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
            }
        });
    }

    adjustElementHeight(paymentList: ElementRef, deviceType: 'desktop' | 'tablet' | 'mobile', bottomHeight:any) {
            const viewportWidth = window.innerWidth;
        
            let maxHeight = 'none';
            let overflowY = 'visible';
        
            if (deviceType === 'mobile' && viewportWidth <= MOBILE_VIEWPORT_MAX_WIDTH) {
            const viewportHeight = window.innerHeight;
            const offsetTop = paymentList.nativeElement.offsetTop;
            const calculatedMaxHeight = viewportHeight - offsetTop - bottomHeight;
        
            if (paymentList.nativeElement.scrollHeight > calculatedMaxHeight) {
                maxHeight = `${calculatedMaxHeight}px`;
                overflowY = 'scroll';
            } else {
                maxHeight = '398px';
                overflowY = 'auto';
            }
            } else if (deviceType === 'tablet' && viewportWidth > MOBILE_VIEWPORT_MAX_WIDTH && viewportWidth <= TABLET_VIEWPORT_MAX_WIDTH) {
            const viewportHeight = window.innerHeight;
            const offsetTop = paymentList.nativeElement.offsetTop;
            const calculatedMaxHeight = viewportHeight - offsetTop - bottomHeight;
        
            if (paymentList.nativeElement.scrollHeight > calculatedMaxHeight) {
                maxHeight = `${calculatedMaxHeight}px`;
                overflowY = 'scroll';
            } else {
                maxHeight = '398px';
                overflowY = 'auto';
            }
            } else {
            maxHeight = 'none';
            overflowY = 'visible';
            }
        
            paymentList.nativeElement.style.maxHeight = maxHeight;
            paymentList.nativeElement.style.overflowY = overflowY;
    }
    async getCounts() {
        await this.smsService.getDashboardgetPharmaInfo();
        const dashboardCounts = await this.smsService.getDashboardCounts();
        const isBirthday = this.isTodayBirthday(dashboardCounts?.patDOB);
        if(dashboardCounts) {
            const DboardCounts = {
                "Pending_Payments": dashboardCounts?.pendingPaymentsCount,
                "My_Meds": dashboardCounts?.rxHistoryCount,
                "Ready_to_Dispense": dashboardCounts?.readyForPickupCount,
                "Refills_Due": dashboardCounts?.refillDueRxCount,
                "New_Prescription": dashboardCounts?.newErxCount,
                "patientName": dashboardCounts?.patientName,
                "patDOB": dashboardCounts?.patDOB,
                "isTodayBirthday": isBirthday
            }
            localStorage.setItem('DboardCounts', JSON.stringify(DboardCounts));
            return DboardCounts;
        }
    }
    isTodayBirthday(patDOB: string): boolean {
        if (!patDOB) {
            return false;
        }

        const dob = new Date(patDOB);
        const today = new Date();
        return dob.getMonth() === today.getMonth() && dob.getDate() === today.getDate();
    }

}